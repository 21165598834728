import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function ManageCourseContent() {
  return (
    <div classNameName="page-container">
      <header className="header" id="headerid">
        <Link to="#headerid" className="logo">
          Maverick Brilliance
        </Link>
        <nav className="nav-items">
          <a href="javascript:history.back()">Back</a>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", fontWeight: "bold" }}>
        Manage Course Content
      </p>
      <hr width="100%" />
      <div className="grid-container-student-admin">
        <div>
          <div className="index-items">
            <ul>
              <li>
                <HashLink to="/ManageCourseContent#managecoursecontent">
                  Manage Course Content
                </HashLink>
              </li>
              <li>
                <HashLink href="/ManageCourseContent#managecourseobjectives">
                  Manage Course Objectives
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div id="managecoursecontent" style={{ textAlign: "left" }}>
            <p>Manage Course Content</p>
            Enter Course Id and click on search button <br />
            <br />
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Course Content will be shown in this space</p>
            <hr />
          </div>
          <div id="managecourseobjectives" style={{ textAlign: "left" }}>
            <p>Manage Course Objectives</p>
            Enter Course Id and click on search button <br />
            <br />
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Course Objectives will be shown in this space</p>
            <hr />
          </div>
        </div>
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default ManageCourseContent;
