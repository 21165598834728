import "./Header.css";
import { Link } from "react-router-dom";
import "./gridcontainer.css";
import { HashLink } from "react-router-hash-link";
function studentAdmin() {
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <Link to="#headerid" class="logo">
          Maverick Brilliance
        </Link>
        <nav class="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", fontWeight: "bold" }}>
        STUDENT MANAGEMENT
      </p>
      <hr width="100%" />
      <div class="grid-container-student-admin">
        <div>
          <div class="index-items">
            <ul>
              <li>
                <HashLink to="/studentAdmin#managestudent">
                  Manage Student
                </HashLink>
              </li>
              <li>
                <HashLink to="/studentAdmin#managecourses">
                  Manage Courses
                </HashLink>
              </li>
              <li>
                <HashLink to="/studentAdmin#managepermissions">
                  Manage Permissions
                </HashLink>
              </li>
              <li>
                <HashLink to="/studentAdmin#managereports">Reports</HashLink>
              </li>
              <li>
                <HashLink to="#manageanalytics">Analytics</HashLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div id="managestudent" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>
              <b>Student Management</b>
              <br />
              Enter Id and click on search button
            </p>
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <br />
            <br />
            <p style={{ fontSize: "larger" }}>
              Details will be shown in this space
            </p>
            <hr style={{ width: "100%" }} />
            <br />
          </div>
          <div id="managecourse" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>
              <b>Course Management</b>
              <br />
              Enter Id and click on search button
            </p>
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <br />
            <br />
            <p style={{ fontSize: "larger" }}>
              Details will be shown in this space
            </p>
            <hr style={{ width: "100%" }} />
            <br />
          </div>
          <div id="managepermission" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>
              <b>Permission Management</b>
              <br />
              Enter Id and click on search button
            </p>
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <br />
            <br />
            <p style={{ fontSize: "larger" }}>
              Details will be shown in this space
            </p>
            <hr style={{ width: "100%" }} />
            <br />
          </div>
          <div id="managereport" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>
              <b>Report Management</b>
              <br />
              Enter Id and click on search button
            </p>
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <br />
            <br />
            <p style={{ fontSize: "larger" }}>
              Details will be shown in this space
            </p>
            <hr style={{ width: "100%" }} />
            <br />
          </div>
          <div id="manageanalytics" style={{ textAlign: "left" }}>
            <p style={{ fontSize: "larger" }}>
              <b>Analytics Management</b>
              <br />
              Enter Id and click on search button
            </p>
            <label for="idno"></label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <br />
            <br />
            <p style={{ fontSize: "larger" }}>
              Details will be shown in this space
            </p>
            <hr style={{ width: "100%" }} />
            <br />
          </div>
        </div>
        <div></div>
      </div>
      <footer className="footer" id="footer">
        <div className="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default studentAdmin;
