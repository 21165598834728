import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
function SupportAndFeedback() {
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <a href="#headerid" class="logo">
          Maverick br /illiance
        </a>
        <nav class="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", fontWeight: "bold" }}>
        SUPPORT AND FEEDBACK
      </p>
      <hr width="100%" />
      <div class="grid-container-student-admin">
        <div>
          <div class="index-items">
            <ul>
              <li>
                <HashLink to="SupportAndFeedback#studentsupport">
                  Student Support Programs
                </HashLink>
              </li>
              <li>
                <HashLink to="SupportAndFeedback#reports">Reports</HashLink>
              </li>
              <li>
                <HashLink to="SupportAndFeedback#feedback">Feedback</HashLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div id="studentsupport" style={{ textAlign: "left" }}>
            <p>Student Support Programs</p>
            Enter Student Id and click on search button <br />
            <br />
            <label for="idno">
              <b>Student ID</b>
            </label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Enroll</button>
            <p>Students will be enrolled into support programs in this space</p>
            <hr />
          </div>
          <div id="reports" style={{ textAlign: "left" }}>
            <p>Reports</p>
            Enter Student Id and click on search button <br />
            <br />
            <label for="idno1">
              <b>Student ID</b>
            </label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno1"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>
              Reports of students enrolled in support will be shown in this
              space
            </p>
            <hr />
          </div>
          <div id="feedback" style={{ textAlign: "left" }}>
            <p>Feedback</p>
            Anonymous feedback from students will be displayed
            <br />
            <br />
            <p>Feedback from students will be shown in this space</p>
            <hr />
          </div>
        </div>
      </div>
      <footer class="footer" id="footer">
        <div class="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}
export default SupportAndFeedback;
