import Header from "./Header.js";
import Footer from "./Footer.js";

import "./heading.css";
import "./paragraph.css";
import "./gridcontainer.css";
import "./button.css";
import "./image.css";
import "./formfeild.css";
function SignUp() {
  return (
    <div className="page-container">
      <Header />
      <div className="grid-container-forgotpassword">
        <div>
          <img
            className="img-signup"
            style={{ marginTop: "30%", height: "50%", width: "75%" }}
            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSol3WnWppNI_6vqGJAHEBHYaXiH7waMhI3fumWpam5iVPQzcMD"
          />
        </div>
        <div className="info" id="info">
          <form className="container" method="post" name="signupform">
            <h1 align="center">Sign Up</h1>
            <label for="name">
              <b>Name</b>
            </label>
            <input type="text" placeholder="Enter Name" name="name" required />
            <label for="studentid">
              <b>ID Number</b>
            </label>
            <input type="text" placeholder="ID" name="studentid" required />

            <label for="email">
              <b>Email</b>
            </label>
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              required
            />

            <label for="contact">
              <b>Contact</b>
            </label>
            <input
              type="text"
              placeholder="Enter Contact Number"
              name="contact"
              required
            />

            <label for="psw">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="psw"
              required
            />

            <button
              type="submit"
              onclick="window.alert('User Registered')"
              className="btn-signup"
            >
              Sign Up
            </button>
            <br />
            <br />
            <h3 align="center">
              Already Have An Account? Login to Your Account
            </h3>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
