import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function Reviews() {
  return (
    <div className="page-container">
      <header class="header" id="headerid">
        <Link to="#headerid" class="logo">
          Maverick br /illiance
        </Link>
        <nav class="nav-items">
          <Link to="javascript:history.back()">Back</Link>
        </nav>
      </header>
      <p style={{ fontSize: "x-large", fontWeight: "bold" }}>REVIEWS</p>
      <hr width="100%" />
      <div class="grid-container-student-admin">
        <div>
          <div class="index-items">
            <ul>
              <li>
                <HashLink to="reviews.html#instructorreviews">
                  Instructor Reviews
                </HashLink>
              </li>
              <li>
                <HashLink to="reviews.html#coordinatorreviews">
                  Coordinator Reviiews
                </HashLink>
              </li>
              <li>
                <HashLink to="reviews.html#coursereviews">
                  Course Reviews
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div id="instructorreviews" style={{ textAlign: "left" }}>
            <p>
              <b>Instructor Reviews</b>
            </p>
            Enter Instructor Id and click on search button <br />
            <br />
            <label for="idno">
              <b>Instructor ID</b>
            </label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Instructor's Reviews will be shown in this space</p>
            <hr />
          </div>
          <div id="coordinatorreviews" style={{ textAlign: "left" }}>
            <p>
              <b>Coordinator Reviews</b>
            </p>
            Enter Coordinator Id and click on search button <br />
            <br />
            <label for="idno1">
              <b>Coordinator ID</b>
            </label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno1"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Coordinator's Reviews will be shown in this space</p>
            <hr />
          </div>
          <div id="coursereviews" style={{ textAlign: "left" }}>
            <p>
              <b>Course Reviews</b>
            </p>
            Enter Course Id and click on search button <br />
            <br />
            <label for="idno2">
              <b>Instructor ID</b>
            </label>
            <br />
            <input
              style={{ width: "50%" }}
              type="text"
              placeholder="Enter Id Number"
              name="idno2"
              required
            />
            {"\t"}
            <button type="submit">Search</button>
            <p>Course's Reviews will be shown in this space</p>
            <hr />
          </div>
        </div>
      </div>
      <footer class="footer" id="footer">
        <div class="copy">
          &copy; Copyright by Team 10, 2023. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Reviews;
